<template>
  <!-- ДОДАТИ НОВІ ПОЛЯ! -->
  <div class="kyc bgcbdr">
    <!-- Перевірка KYC -->
    <h3 class="mb2">{{ $t("kyc_dashboard_title_h3") }}</h3>
    <div class="child_KYC" v-if="status_kyc != 0">
      <h3>
        <!-- Статус перевірки KYC: -->
        {{ $t("kyc_dashboard_status_title") }} 
        <!-- 
          On inspection
          Confirmed
          Refused
         -->
        <span v-if="status_kyc === 1" style="color:orange">{{ $t("kyc_dashboard_status_1") }}</span>
        <span v-if="status_kyc === 2" style="color:lawngreen">{{ $t("kyc_dashboard_status_2") }}</span>
        <span v-if="status_kyc === 3" style="color:red">{{ $t("kyc_dashboard_status_3") }}</span>
        
      </h3>
      <!-- Wait for confirmation. The process takes from 30 minutes to 4 hours. -->
      <h4 v-if="status_kyc === 1">{{ $t("kyc_dashboard_status_1_explain1") }}</h4>
      <!-- Your data has been checked and confirmed, now you can use the exchange for fiat funds! -->
      <h4 v-if="status_kyc === 2">{{ $t("kyc_dashboard_status_2_explain2") }}</h4>
      <!-- Unfortunately, you have been refused! Please contact us at support@exmoney.exchange for details -->
      <h4 v-if="status_kyc === 3">{{ $t("kyc_dashboard_status_3_explain3") }}</h4>
    </div>
    <div class="child_KYC" v-if="status_kyc === 0 && kycstate === false">
      <!-- Обов'язковий для обміну на фіатні валюти! -->
      <h3>{{ $t("kyc_dashboard_rule_h3") }}</h3>
      <div class="kyc-list-title">
        <!-- Вам необхідно надати: -->
        <h5>{{ $t("kyc_dashboard_rule_h5") }}</h5>
        <ul class="needed-items-list">
          <!-- 
            - Паспорт (перша сторінка)
- ID-картка (передній бік)
- документ, що засвідчує особу (передня сторона)
           -->
          <li>
            {{ $t("kyc_dashboard_rule_li1_1") }} <br> 
            {{ $t("kyc_dashboard_rule_li1_2") }} <br>
            {{ $t("kyc_dashboard_rule_li1_3") }} <br>
            {{ $t("kyc_dashboard_rule_li1_4") }}
          </li>
          <!-- Фото (селфі) особи з документом, що посвідчує особу. -->
          <li>{{ $t("kyc_dashboard_rule_li2_1") }}</li>
        </ul>
      </div>
    </div>
    <hr v-if="status_kyc === 0 && kycstate === false">
    <button class="kyc_button" type="button" @click="makekyc = !makekyc" v-if="status_kyc === 0 && kycstate === false">{{ makekyc ?
      'Close' : 'Add' }}</button>

    <div class="border_block_card" v-if="makekyc === true && kycstate === false">

      <form action="#" method="post" enctype="multipart/form-data">
        <div class="main_card">
          <div class="upload_files">
            <!--  -->
            <h3 class="card_title">{{ $t("kyc_dashboard_upload_1") }}</h3>
            <label for="IdentityDocumentsInput" class="drop-container" id="dropcontainer-1">
              <span class="drop-title">{{ $t("kyc_dashboard_selected_file") }}</span>
              <input type="file" name="photo1" id="IdentityDocumentsInput" accept="image/png, image/jpg, image/jpeg"
                v-on:change="handleFilesUploadVirtualScreanshot()" ref="filesScreanshot" required>
            </label>
            <!-- Обов'язкове поле -->
            <p class="error_form" v-if="!$v.uploaded_ident_doc.required">{{ $t("kyc_dashboard_required") }}</p>
          </div>
          <div class="explain_upload_files">
            <img src="@/assets/cards/doc.jpg" alt="" v-if="uploaded_ident_doc == null">
            <img :src="uploaded_ident_doc" alt="" v-if="uploaded_ident_doc">
          </div>
        </div>
        <div class="main_card">
          <div class="upload_files">
            <!--  -->
            <h3 class="card_title">{{ $t("kyc_dashboard_upload_2") }}</h3>
            <label for="IdentityDocumentsInput1" class="drop-container" id="dropcontainer-2">
              <span class="drop-title">{{ $t("kyc_dashboard_selected_file") }}</span>
              <input type="file" name="photo2" id="IdentityDocumentsInput1" accept="image/png, image/jpg, image/jpeg"
                v-on:change="handleFilesUploadVirtualFact()" ref="filesFact" required>
            </label>
            <!-- Обов'язкове поле -->
            <p class="error_form" v-if="!$v.uploaded_selfi.required">{{ $t("kyc_dashboard_required") }}</p>
            
          </div>
          <div class="explain_upload_files">
            <img src="@/assets/cards/selfi.png" alt="" v-if="uploaded_selfi == null" style=" background: #fff; ">
            <img :src="uploaded_selfi" alt="" v-if="uploaded_selfi">
          </div>
        </div>
      </form>
      <div class="kyc_btn">

        <div class="check_box">
          <input type="checkbox" name="" id="" :value="true" :unchecked-value="false"
            v-model="$v.allow_data.$model" @change="$v.allow_data.$touch()" />
            <!-- I agree to the processing my personal data. -->
          <h4 @click="allow_data = !allow_data">{{ $t("kyc_dashboard_agree_1") }}<br> {{ $t("kyc_dashboard_agree_2") }}</h4>
        </div>
        <!-- Надіслати -->
        <button class="kyc_button submit-button" type="button" @click="submit_kyc">{{ $t("kyc_dashboard_btn_submit") }}</button>
      </div>
      <!-- Обов'язкове поле -->
      <p class="error_form" v-if="!$v.allow_data.sameAs">{{ $t("kyc_dashboard_required") }}</p>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { validationMixin } from 'vuelidate'
import {
  required,
  sameAs
} from 'vuelidate/lib/validators'
export default {
  name: "kyc_dashboard",
  data() {
    return {
      kycstate: false,
      makekyc: false,
      identitydocuments: null,
      selfie: null,
      kycprogress: null,
      allow_data: false,
      error_file_1: "",
      error_file_2: "",
      error_allow_data: false,
      status_kyc: 0,
      uploaded_ident_doc:null,
      uploaded_selfi:null,
    }
  },
  mixins: [validationMixin],
  validations() {
    return {
      uploaded_ident_doc:{
        required
      },
      uploaded_selfi:{
        required
      },
      allow_data: {
        sameAs: sameAs(() => true)
      },
    }
  },
  methods: {
    handleFilesUploadVirtualScreanshot() {
      let uploadedFiles = this.$refs.filesScreanshot.files;
      this.image_b64VirtualScreanshot(uploadedFiles[0])
    },
    image_b64VirtualScreanshot(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.iconBase64 = reader.result
        this.uploaded_ident_doc = this.iconBase64
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    handleFilesUploadVirtualFact() {
      let uploadedFiles = this.$refs.filesFact.files;
      this.image_b64VirtualFact(uploadedFiles[0])
    },
    image_b64VirtualFact(file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        this.iconBase64 = reader.result
        this.uploaded_selfi = this.iconBase64
      }
      reader.onerror = function (error) {
        console.log('Error: ', error)
      }
    },
    submit_kyc() {
      this.$v.allow_data.$touch();
      if (this.$v.allow_data.$anyError) {
        return
      }
      this.$v.uploaded_ident_doc.$touch();
      if (this.$v.uploaded_ident_doc.$anyError) {
        return
      }
      this.$v.uploaded_selfi.$touch();
      if (this.$v.uploaded_selfi.$anyError) {
        return
      }
      let data = {
        'identity_document': this.uploaded_ident_doc,
        'selfie': this.uploaded_selfi,
        'allow_data': this.allow_data,
      }
      axios.post(
        "/auth/set_kyc_verification/",
        data
      ).then(data => {
        this.kycstate = data.data['state']
        // status_kyc: (0, 'Не розпочато',),(1, 'Перевіряється'),(2, 'Підтверджено'),(3, 'Відмовлено')
        this.status_kyc = data.data['status_kyc']
        this.makekyc = !this.makekyc
        this.uploaded_ident_doc = null
        this.uploaded_selfi = null
        this.allow_data = false
        
      }).catch(error => {
        console.error(error);
      });  
    },
    checkKyc() {
      const response = axios.post('/auth/kyc_check/');

      response.then(data => {
        this.kycstate = data.data['state']
        // status_kyc: (0, 'Не розпочато',),(1, 'Перевіряється'),(2, 'Підтверджено'),(3, 'Відмовлено')
        this.status_kyc = data.data['status_kyc']
      }).catch(error => {
        console.error(error);
      });
    }
  },
  mounted() {
    this.checkKyc();
  }
}
</script>

<style scoped>
.border_block_card {
  border: solid 1px;
  margin-top: 30px;
  border-radius: 5px;
  padding: 10px 20px;
}
.main_card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.upload_files {
  width: 50%;
}

.card_title {
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

.explain_upload_files {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.explain_upload_files img {
  width: 90%;
}
.error_form {
  color: red;
}

.check_box input[type="checkbox"] {
  height: 24px;
  width: 24px;
  border: solid 1px #fff !important;
  background-color: inherit !important;
  cursor: pointer;
  margin: 0 20px 0px 0;
}

.check_box {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 25px;
  align-items: center;
}

.check_box input {
  width: 100%;
  height: 40px;
}

.check_box h4 {
  margin: 0;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}

.kyc_btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

input[type=file] {
  width: 350px;
  height: 60px;
  max-width: 100%;
  color: #444;
  padding: 10px;
  background: #6e7ee7;
  border-radius: 10px;
  border: 1px solid #555;
  font-size: 14px;
}

input[type=file]::file-selector-button {
  color: #3441a6;
  font-size: 18px !important;
  height: 40px;
  font-size: large;
  font-weight: bold;
  background: white;
  border: 1px solid grey;
  border-radius: 5px;
  display: none;
}

input[type=file]::file-selector-button:hover {
  background: #485bd0;
  color: white;
  border-color: grey;
}

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 20px;
  border-radius: 10px;
  border: 2px dashed white;
  cursor: pointer;
  transition: background .2s ease-in-out, border .2s ease-in-out;
}

.drop-container:hover {
  background: #404fb4;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #212b70;
}

.drop-title {
  color: #212b70;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color .2s ease-in-out;
}

.title {
  margin-left: 12%;
  margin-top: 4%;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

:root {
  --c-action-primary: #2e44ff;
  --c-action-primary-accent: #e9e5ff;
  --c-action-secondary: #e5e5e5;
  --c-text-primary: #0d0f21;
  --c-text-secondary: #6a6b76;
  --c-background-primary: #d0d1de;
}

body {
  font-family: "Inter", sans-serif;
  color: var(--c-text-primary);
  background-color: var(--c-background-primary);
  line-height: 1.5;
}


select,
textarea {
  font: inherit;
}


.logo-circle svg {
  max-width: 1.5rem;
}


.upload-area-icon svg {
  max-height: 100%;
  max-width: 100%;
}


.upload-area-description strong {
  color: var(--c-action-primary);
  font-weight: 700;
}


.kyc-list-title {
  margin: 10px 0;
}

.needed-items-list {
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  margin: 10px 0;
}

.needed-items-list li {
  font-size: 16px;
  line-height: 28px;
}

.needed-items-list li a {
  color: #0e9cff;
  cursor: pointer;
  text-decoration: none;
}

.child {
  margin-left: 12px;
}


.kyc_button {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2a3587;
  background: #fff;
  border: none;
  width: 170px;
  padding: 10px;
  cursor: pointer;
  border: solid 1px #fff;
}

.kyc_button:hover {
  font-size: 20px;
  color: #fff;
  background: #2a3587;
}

.modal-kyc h4 {
  background-color: white;
  color: black;
  font-size: 18px;
  padding: 0;
}

.modal-kyc button {
  background-color: #3e4bb0;
  color: white;
  font-size: 16px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 0;
  border: none;

}

@media (min-width: 1200px) and (max-width: 1440px) {
  .card_title {
    font-size: 14px;
    line-height: 18px;
  }
  .drop-container{
    margin: 20px 0;
  }
  .main_big p, .big_block p{
    font-size: 14px;
    line-height: 14px;
  }
  .check_box h4{
    font-size: 14px;
  }
  
}

@media (min-width: 992px) and (max-width: 1199px) {
  .card_title {
    font-size: 14px;
    line-height: 18px;
  }
  .main_big p, .big_block p{
    font-size: 14px;
    line-height: 14px;
  }
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .kyc_button{
    font-size: 18px;
    width: 100px;
  }
  .drop-title {
    font-size: 16px;
  }
  .drop-container{
    margin: 20px 0;
    padding: 10px;
  }
  .kyc_button:hover{
    font-size: 18px;
  }
  .main_card {
    margin-bottom: 20px;
}
  .check_box h4{
    font-size: 14px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .card_title {
    font-size: 14px;
    line-height: 18px;
  }
  .main_big p, .big_block p{
    font-size: 14px;
    line-height: 14px;
  }
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .kyc_button{
    font-size: 18px;
    width: 100px;
  }
  .drop-title {
    font-size: 16px;
  }
  .drop-container{
    margin: 20px 0;
    padding: 10px;
  }
  .kyc_button:hover{
    font-size: 18px;
  }
  .main_card {
    margin-bottom: 20px;
}
  .check_box h4{
    font-size: 14px;
  }
  .needed-items-list {
    margin: 10px 15px;
}
}

@media (min-width: 480px) and (max-width: 767px) {
  .card_title {
    font-size: 14px;
    line-height: 18px;
  }
  .main_big p, .big_block p{
    font-size: 14px;
    line-height: 14px;
  }
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .kyc_button{
    font-size: 18px;
    width: 100px;
  }
  .drop-title {
    font-size: 16px;
  }
  .drop-container{
    margin: 20px 0;
    padding: 10px;
  }
  .kyc_button:hover{
    font-size: 18px;
  }
  .main_card {
    margin-bottom: 20px;
}
  .check_box h4{
    font-size: 14px;
  }
  .needed-items-list {
    margin: 10px 15px;
}
}

@media (max-width: 479px) {
  .card_title {
    font-size: 14px;
    line-height: 18px;
  }
  .main_big p, .big_block p{
    font-size: 14px;
    line-height: 14px;
  }
  .needed-items-list li {
    font-size: 14px;
    line-height: 20px;
  }
  .kyc_button{
    font-size: 18px;
    width: 100px;
  }
  .drop-title {
    font-size: 16px;
  }
  .drop-container{
    margin: 10px 0px 5px 0;
    padding: 10px;
  }
  .kyc_button:hover{
    font-size: 18px;
  }
  .main_card {
    margin-bottom: 20px;
}
  .check_box h4{
    font-size: 14px;
  }
  .check_box{
    margin-bottom: 5px;
  }
  .main_card{
    flex-direction: column;
  }
  .upload_files, .explain_upload_files{
    width: 100%;
  }
  .explain_upload_files img{
    width: 100%;
    margin-top: 15px;
  }
  .check_box input[type="checkbox"]{
    margin: 0 10px 0 0;
  }
  .needed-items-list {
    margin: 10px 15px;
}
}

</style>