<template>
	<div class="sa_dashboard bgcbdr">
		<h3 class="mb3">Super Admin</h3>
		<div class="menu_admin">
			<ul>
				<li @click="menu_admin(1)">Balance MAIN</li>
				<li @click="menu_admin(2)">Balance TRADE</li>
				<li @click="menu_admin(3)">Transfer_MainSpot</li>
				<li @click="menu_admin(4)">Swap</li>
				<li @click="menu_admin(5)">Transfer_SpotMain</li>
				<li @click="menu_admin(6)">Withdraw</li>
				<li @click="menu_admin(7)">Tasks</li>
			</ul>
		</div>
		<div class="main_page_sa">
			<div v-if="page_menu_admin == 0" class="item_page_sa">
				<h1>Смотри баланс и управляй обменом вручную</h1>
				
			</div>
			<!-- PAGE #1 -->
			<div v-if="page_menu_admin == 1" class="item_page_sa">
				<h1>Баланс "MAIN"</h1>
				<!-- <div class="filtr">
					<input type="checkbox" v-model="hideZero">
					<p>Скрыть нулевой баланс</p>
				</div> -->
				<ul class="answ_balance" v-if="answ_balance_main">
					<li v-for="(item,inx) in answ_balance_main" :key="inx">
						<span class="symbol">{{inx}}</span> 
						<span class="balance">{{ item.main_balance }}</span>
					</li>
				</ul>
				<div v-else-if="answ_balance_main_error">
					<h2>{{ answ_balance_main_error }}</h2>
				</div>
				<div v-else>
					<h2>Данные загружаются. Подождите</h2>
				</div>
			</div>
			<!-- PAGE #2 -->
			<div v-if="page_menu_admin == 2" class="item_page_sa">
				<h1>Баланс "TRADE"</h1>
				<!-- <div class="filtr">
					<input type="checkbox" v-model="hideZeroTrade">
					<p>Скрыть нулевой баланс</p>
				</div> -->
				<ul class="answ_balance" v-if="answ_balance_trade">
					<li>
						<span class="symbol">Символ</span> 
						<span class="balance"><span>Доступні</span> | <span>Заморожені</span></span>
					</li>
					<li v-for="(item,inx) in answ_balance_trade" :key="inx">
						<span class="symbol">{{inx}}</span> 
						<span class="balance"><span>{{ item.available }}</span>  <span>{{ item.freeze }}</span></span>
					</li>
				</ul>
				<div v-else-if="answ_balance_trade_error">
					<h2>{{ answ_balance_trade_error }}</h2>
				</div>
				<div v-else>
					<h2>Данные загружаются. Подождите</h2>
				</div>
			</div>
			<!-- PAGE #3 -->
			<div v-if="page_menu_admin == 3" class="item_page_sa">
				<h1>Transfer_MainSpot</h1>
				<div class="form_start_process">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page3">
					<button class="btn-blue" @click="startPage3">REQUEST</button>
				</div>
				<div v-if="answear_page3">
					<p>{{ answear_page3 }}</p>
				</div>
				<div>
					if Error
				</div>
			</div>
			<!-- PAGE #4 -->
			<div v-if="page_menu_admin == 4" class="item_page_sa">
				<h1>Swap</h1>
				<div class="form_start_process">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page4">
					<button class="btn-blue" @click="startPage4">REQUEST</button>
				</div>
				<div v-if="answear_page4">
					<p>{{ answear_page4 }}</p>
				</div>
				<div>
					if Error
				</div>
			</div>
			<!-- PAGE #5 -->
			<div v-if="page_menu_admin == 5" class="item_page_sa">
				<h1>Transfer_SpotMain</h1>
				<div class="form_start_process">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page5">
					<button class="btn-blue" @click="startPage5">REQUEST</button>
				</div>
				<div v-if="answear_page5">
					<p>{{ answear_page5 }}</p>
				</div>
				<div>
					if Error
				</div>
			</div>
			<!-- PAGE #6 -->
			<div v-if="page_menu_admin == 6" class="item_page_sa">
				<h1>Withdraw</h1>
				<div class="form_start_process">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page6">
					<button class="btn-blue" @click="startPage6">REQUEST</button>
				</div>
				<div v-if="answear_page6">
					<p>{{ answear_page6 }}</p>
				</div>
				<div>
					if Error
				</div>
			</div>
			<!-- PAGE #6 startPage7-->
			<div v-if="page_menu_admin == 7" class="item_page_sa">
				<h1>Manual celery task</h1>
				<div class="btn-grp">
					<button class="btn-blue" @click="domesticMenu_7(1)">Перевірити статус</button>
					<button class="btn-blue" @click="domesticMenu_7(2)">Поставити задачу</button>
					<button class="btn-blue" @click="domesticMenu_7(3)">Закрыть все задачи!</button>
				</div>
				<div v-if="page_7_domestic_menu==0">
					<p>Обери з меню дію</p>
				</div>
				<div class="form_start_process_2" v-if="page_7_domestic_menu==1">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page7_1_id">
					<button class="btn-blue" @click="startPage7_1">REQUEST</button>
				</div>
				<div class="form_start_process_2" v-if="page_7_domestic_menu==2">
					<input type="text" class="base-input" placeholder="Введите ID транзакции" v-model="input_page7_id">
					<input type="text" class="base-input" placeholder="Текущий стейдж" v-model="input_page7_stage">
					<input type="text" class="base-input" placeholder="Действие" v-model="input_page7_action">
					<button class="btn-blue" @click="startPage7_2">REQUEST</button>
				</div>
				<div class="form_start_process_2" v-if="page_7_domestic_menu==3">
					<button class="btn-blue" @click="startPage7_3">REQUEST</button>
				</div>
				<div v-if="answear_page7_3">
					<h4>{{ answear_page7_3 }}</h4>
				</div>
				<!-- Check tasks -->
				<div v-if="answear_page7_1">
					<div v-for="(item, inx) in answear_page7_1" :key="inx">
						<div v-if="item.id_request">
							<h3>ID : {{ item.id }}</h3>
							<h3>ID Request : {{ item.id_request }}</h3>
							<h3>STAGE : {{ item.stage }}</h3>
							<h3>MESSAGE : {{ item.message }}</h3>
							<h3>FULL ERROR : {{ item.parse_resp }}</h3>
						</div>
						
					</div>
				</div>
				<div>
					if Error
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from '../../../axios';

export default {
	name: "super-admin",
	data() {
		return {
			page_menu_admin: 0,
			// For Balance
			url_1_balance_main: "v1/private/balance-main/",
			answ_balance_main:null,
			answ_balance_main_error:null,
			hideZero:false,
			url_2_balance_trade: "v1/private/balance-trade/",
			hideZeroTrade:false,
			answ_balance_trade:null,
			answ_balance_trade_error:null,
			// For Exchange
			url_3_transfer_main_to_spot: "v1/private/transfer/main-spot/",
			input_page3:'',
			answear_page3:null,
			url_4_swap: "v1/private/swap/",
			input_page4:'',
			answear_page4:null,
			url_5_transfer_spot_to_main: "v1/private/transfer/spot-main/",
			input_page5:'',
			answear_page5:null,
			url_6_withdraw: "v1/private/withdraw/",
			input_page6:'',
			answear_page6:null,
			url_7_task_celery: "v1/private/manual-task/",
			input_page7_id:'',
			input_page7_stage:'',
			input_page7_action:'',
			answear_page7:null,
			page_7_domestic_menu:0,
			// Check Tasks
			input_page7_1_id:'',
			url_7_1_check_task: "v1/private/manual-check-task/",
			answear_page7_1:null,
			url_7_3_check_task: "v1/private/manual-close-tasks/",
			answear_page7_3:null,
		};
	},
	watch:{
		hideZero:function (newValue){
			if (newValue == true){
				for (let item in this.answ_balance_main){
					if (this.answ_balance_main[item]["main_balance"] == '0'){
						delete this.answ_balance_main[item]
					}
				}
			}else{
				this.menu_1_balance_main()
			}
		},
		hideZeroTrade:function (newValue){
			if (newValue == true){
				for (let item in this.answ_balance_trade){
					if (this.answ_balance_trade[item]["available"] == '0' && this.answ_balance_trade[item]["freeze"] == '0'){
						delete this.answ_balance_trade[item]
					}
				}
			}else{
				this.menu_2_balance_trade()
			}
		},
	},
	methods: {
		menu_admin(page) {
			this.page_menu_admin = page
			if (page == 1){
				this.menu_1_balance_main()
			}
			if(page == 2){
				this.menu_2_balance_trade()
			}
		},
		async menu_1_balance_main() {
			var self = this
			try {
				await axios.post(this.url_1_balance_main)
					.then(function (response) {
						self.answ_balance_main = response.data
						for (let item in self.answ_balance_main){
							if (self.answ_balance_main[item]["main_balance"] == '0'){
								delete self.answ_balance_main[item]
							}
						}
					})
					.catch(function (error) {
						self.answ_balance_main_error = error
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async menu_2_balance_trade() {
			var self = this
			try {
				await axios.post(this.url_2_balance_trade)
					.then(function (response) {
						self.answ_balance_trade = response.data
						for (let item in self.answ_balance_trade){
							if (self.answ_balance_trade[item]["available"] == '0' && self.answ_balance_trade[item]["freeze"] == '0'){
								delete self.answ_balance_trade[item]
							}
						}
					})
					.catch(function (error) {
						self.answ_balance_trade_error = error
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage3() {
			var self = this
			let data = {
				"id_request": this.input_page3,
			}
			try {
				await axios.post(this.url_3_transfer_main_to_spot,data)
					.then(function (response) {
						self.answear_page3 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage4() {
			var self = this
			let data = {
				"id_request": this.input_page4,
			}
			try {
				await axios.post(this.url_4_swap,data)
					.then(function (response) {
						self.answear_page4 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage5() {
			var self = this
			let data = {
				"id_request": this.input_page5,
			}
			try {
				await axios.post(this.url_5_transfer_spot_to_main,data)
					.then(function (response) {
						self.answear_page5 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		
		async startPage6() {
			var self = this
			let data = {
				"id_request": this.input_page6,
			}
			try {
				await axios.post(this.url_6_withdraw,data)
					.then(function (response) {
						self.answear_page6 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage7_1() {
			var self = this
			let data = {
				"id_request": this.input_page7_1_id
			}
			try {
				await axios.post(this.url_7_1_check_task,data)
					.then(function (response) {
						self.answear_page7_1 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage7_2() {
			var self = this
			let data = {
				"id_request": this.input_page7_id,
				"current_stage": this.input_page7_stage,
				"action": this.input_page7_action
			}
			try {
				await axios.post(this.url_7_task_celery,data)
					.then(function (response) {
						self.answear_page7 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		async startPage7_3() {
			var self = this
			try {
				await axios.post(this.url_7_3_check_task)
					.then(function (response) {
						self.answear_page7_3 = response.data
					})
					.catch(function (error) {
						console.error("Error:",error)
					});

			} catch (e) {
				console.error("Error:",e)
			}
		},
		
		domesticMenu_7(page_inside){
			this.page_7_domestic_menu = page_inside
		}
	},
	mounted() {

	}
};
</script>

<style>
/* faq_dashboard
faq_content
faq_left */
.sa_dashboard {
	color: #fff;
}

.sa_dashboard h3 {
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 28px;
}

/** HEADER MENU */
.menu_admin {
	display: flex;
	flex-direction: row;
	width: 100%;
	border: solid 2px;
	padding: 20px 0;
}

.menu_admin ul {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0 20px;
}

.menu_admin ul li {
	cursor: pointer;
}

.menu_admin ul li:hover {
	text-decoration-line: underline;
}

/** CONTENT **/
.main_page_sa {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: auto;
}

.item_page_sa {
	width: 100%;
	padding: 30px 0;
}

.item_page_sa h1 {
	font-size: 20px;
	text-align: center;
}
.answ_balance{
	width: 80%;
    margin: 0 auto;
	max-height: 600px;
	overflow: auto;
}
.answ_balance li {
	font-size: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 50px;
    border-bottom: solid 1px;
}
.item_page_sa .filtr{
	display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 90%;
}
.answ_balance .balance{
	width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn-grp{
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	padding: 30px 0px;
}
.btn-grp .btn-blue{
	width: 35%;
}
.form_start_process{
	padding: 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110px;
    justify-content: space-between;
}
.form_start_process .base-input{
	width: 80%;
}
.form_start_process .btn-blue{
	width: 150px;
}
.form_start_process_2{
	padding: 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.form_start_process_2 .base-input{
	margin: 20px 0;
	width: 80%;
}
.form_start_process_2 .btn-blue{
	width: 150px;
}

@media (min-width: 1200px) and (max-width: 1440px) {}

@media (min-width: 992px) and (max-width: 1199px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (min-width: 480px) and (max-width: 767px) {}

@media (max-width: 479px) {}
</style>
